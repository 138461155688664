<script setup>
import { transl } from "@/composables/useTranslation";
import { useBiblePlans, useBiblePlansProgress } from "~/composables/useBiblePlans";

const { biblePlansColl } = useBiblePlans();
const { activeLocale } = useTranslation();
const { getPlansProgressPercents, hasPlanStarted } = useBiblePlansProgress();

function openPlan(plan) {
  useEvent("bibleplan.read", {
    planModel: plan,
  });
}
</script>
<template>
  <div class="v-bibleplans-list mt-7">
    <!-- plan card -->

    <div class="item-card mt-7" v-for="plan in biblePlansColl">
      <div class="title">{{ transl(plan?.plan_name) }}</div>
      <div class="content-wrapper mt-2">
        <div class="image-wrapper">
          <div class="image mt-3"><img :src="plan?.plan_image" alt="" /></div>

          <!-- progress line -->
          <div
            class="progress-bar mt-2 px-1"
            v-if="hasPlanStarted({ planSlug: plan.plan_slug })"
          >
            <div class="label mb-1">
              {{
                getPlansProgressPercents({
                  planSlug: plan.plan_slug,
                  planDays: plan.plan_days,
                })
              }}
              %
            </div>
            <div class="wrapper-line">
              <div
                class="line"
                :style="{
                  width:
                    getPlansProgressPercents({
                      planSlug: plan.plan_slug,
                      planDays: plan.plan_days,
                    }) + '%',
                }"
              ></div>
            </div>
          </div>
        </div>
        <!-- progress line end -->
        <div class="description-wrapper">
          <div class="description mt-2 px-1">
            {{ plan?.[`plan_descr_${activeLocale}`] }}
          </div>
          <div
            class="easy-button mt-3 mx-1"
            @click="openPlan(plan)"
            v-if="!hasPlanStarted({ planSlug: plan.plan_slug })"
          >
            {{ transl("Start a plan") }}
          </div>
          <div
            class="easy-button-filled mt-3 mx-1"
            @click="openPlan(plan)"
            v-if="hasPlanStarted({ planSlug: plan.plan_slug })"
          >
            {{ transl("Read the plan") }}
          </div>
          <div
            class="easy-button mt-3 mx-1"
            @click="useEvent('plans.deleteplan', { plan: plan })"
            v-if="hasPlanStarted({ planSlug: plan.plan_slug })"
          >
            {{ transl("Complete the plan") }}
          </div>
        </div>
      </div>
    </div>
    <!-- plan card end -->
  </div>
</template>
<style scoped>
.content-wrapper {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 20px;
}
.description-wrapper {
  padding: 3px 13px 0 0;
}
.item-card.mt-7 {
  border: solid 1px #f3f3f3;
  border-radius: 20px;
  padding: 20px;
}
.v-bibleplans-list {
  font-family: "Golos Text";
  padding-bottom: 100px;
}
.title {
  font-size: 23px;
  line-height: 24px;
  color: #000;
  font-weight: 600;
}
@media (max-width: 500px) {
  .content-wrapper {
    display: block;
  }
  .item-card.mt-7 {
    border: none;
    padding: 0;
    border-radius: 0;
  }
}
.image {
  overflow: hidden;
}
.image img {
  border-radius: 10px;
  object-fit: cover;
  height: 191px;
  width: 100%;
}
.label {
  font-size: 12px;
  color: #000;
}
.wrapper-line {
  width: 100%;
  background: #e5e5e5;
  border-radius: 10px;
}
.line {
  width: 10%;
  background: #00bfa4;
  height: 10px;
  border-radius: 10px;
}
.description {
  font-size: 14px;
  line-height: 18px;
}
.easy-button {
  border: solid 1px #00bfa4;
  border-radius: 50px;
  color: #00bfa4;
  font-size: 13px;
  padding: 9px 21px;
  display: inline-block;
  cursor: pointer;
}
.easy-button:hover {
  opacity: 0.8;
}
.easy-button-filled {
  background-color: #00bfa4;
  color: #fff;
  border: solid 1px #00bfa4;
  border-radius: 50px;
  font-size: 13px;
  padding: 9px 21px;
  display: inline-block;
  cursor: pointer;
}
.easy-button-filled:hover {
  opacity: 0.8;
}
</style>
